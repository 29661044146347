<template>
  <v-row>
    <v-col cols="12" md="12" class="infoTravel" v-text="$ml.get('confirmation.errormessage')"/>
    <v-col cols="6" class="pa-10">
      <v-text-field type="password" v-model="checker" hint="Introduce código de seguridad" placeholder="Introduce código seguridad" color="pink" />
    </v-col>
    <v-col cols="6" class="pa-10" v-if="checkOK">
      <form ref="redsys_form" action="https://sis-t.redsys.es:25443/sis/realizarPago" method="post"
            id="redsys_form" name="redsys_form">
        <input type="text" name="Ds_SignatureVersion" v-model="Ds_SignatureVersion" hidden style="display: none"/>
        <input type="text" name="Ds_MerchantParameters" v-model="Ds_MerchantParameters" hidden
               style="display: none"/>
        <input type="text" name="Ds_Signature" v-model="Ds_Signature" hidden style="display: none"/>
        <v-btn
            align="start"
            class="ma-2 justify-start payBT"
            :loading="loading"
            color="#d81d86"
            dark
            @click="payOrder" v-text="$ml.get('travel.form.pay')">
          <template v-slot:loader>
                        <span class="custom-loader">
                          <v-icon light>mdi-cached</v-icon>
                        </span>
          </template>
        </v-btn>
      </form>
    </v-col>
  </v-row>
</template>

<script>

export default {
  name: "Confirmation",

  data() {
    return {
      loading: false,
      travel: [],
      reservation: [],
      passengers: [],
      seats: [],
      files: [],
      checker: '',
      checkOK:false,
      loaded: false,
      paymentData: {
        DS_MERCHANT_AMOUNT: 50,
        DS_MERCHANT_CURRENCY: "978",
        DS_MERCHANT_MERCHANTCODE: "337481998",
        DS_MERCHANT_MERCHANTURL: "https://kariba.autocarsgamon.com/API/client/TPVNotification.php?lang=",
        DS_MERCHANT_ORDER: '',
        DS_MERCHANT_TERMINAL: "1",
        DS_MERCHANT_TRANSACTIONTYPE: "0",
        DS_MERCHANT_URLKO: this.env_url + '/viaje/' + this.$route.params.ref,
        DS_MERCHANT_URLOK: ''
      },
      Ds_MerchantParameters: null,
      Ds_Signature: null,
      Ds_SignatureVersion: null,
    }
  },
  watch:{
    checker: function (chk) {
      if(chk == 2020) this.checkOK = true;
    }
  },
  methods: {
    payOrder() {
      const cart = '18062490S';
      const key = Date.now().toString();
      this.paymentData.DS_MERCHANT_ORDER = this.CryptoJS.AES.encrypt(cart, key).toString().substr(33, 10);
      this.paymentData.DS_MERCHANT_AMOUNT = "" + parseInt(50, 10)
      this.paymentData.DS_MERCHANT_AMOUNT = 50;
      this.checkPayment()
    },
    async checkPayment() {
      this.$ma.trackEvent({
        category: 'paymenterror',
        action: 'go to TPV from paymenterror',
        properties: {travel: this.$route.params.ref, rid: this.$route.params.idc, version: this.version}
      });
      let encodeData = await this.encodePayment()
      if (encodeData) this.$refs.redsys_form.submit()
    },
    async encodePayment() {
      try {
        this.priceForRedsys()
        let merchant = this.$CryptoJS.enc.Utf8.parse(JSON.stringify(this.paymentData))
        this.Ds_MerchantParameters = merchant.toString(this.$CryptoJS.enc.Base64)
        let key = this.$CryptoJS.enc.Base64.parse('sq7HjrUOBfKmC576ILgskD5srU870gJ7')
        let iv = this.$CryptoJS.enc.Hex.parse("0000000000000000");
        let cipher = this.$CryptoJS.TripleDES.encrypt(this.paymentData.DS_MERCHANT_ORDER, key, {
          iv: iv,
          mode: this.$CryptoJS.mode.CBC,
          padding: this.$CryptoJS.pad.ZeroPadding // Redsys dont like padding
        })
        let signature = this.$CryptoJS.HmacSHA256(this.Ds_MerchantParameters, cipher.ciphertext);
        this.Ds_Signature = signature.toString(this.$CryptoJS.enc.Base64)
        this.Ds_SignatureVersion = "HMAC_SHA256_V1"
        return true
      } catch (error) {
        return false
      }
    },
    priceForRedsys() {
      let priceN = 50
      if (Number.isInteger(priceN)) {
        this.paymentData.DS_MERCHANT_AMOUNT = priceN.toString() + '00'
      } else {
        let price_fixed = priceN.toFixed(2).toString()
        let price_string = price_fixed.replace('.', '')
        this.paymentData.DS_MERCHANT_AMOUNT = price_string
      }
    },
  }
}
</script>
<style scoped>
.reservationTitle {
  color: #d81d86;
  font-weight: bold;
  font-size: 18pt;
}


</style>

